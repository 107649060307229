@import './material-theme.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

* {
  box-sizing: border-box;
}

.mat-subtitle-2 {
  color: rgba(0, 0, 0, 0.54);
}

mat-header-cell,
mat-header-cell * {
  text-transform: uppercase;
}

// DHTMLX

.dhx_diagram_item {
  cursor: pointer !important;
}

.shape_content {
  background-color: #d37c6c;
}

.dhx_content_text {
  color: white !important;
}

.dhx_content_title {
  color: white !important;
  fill: white !important;
}

.dhx_selected {
  rect {
    stroke-width: 4;
    stroke: dodgerblue !important;
    transition: all 0.2s;
  }
}

.dhx_toolbar_wp {
  div {
    color: black !important;
  }
}

md-sidenav,
md-sidenav.md-locked-open,
md-sidenav.md-closed.md-locked-open-add-active {
  min-width: 600px !important;
  max-width: 400px !important;
}

.accelerate {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

mat-form-field {
  &.x-large {
    width: 800px;
  }

  &.large {
    width: 600px;
  }

  &.medium {
    width: 400px;
  }

  &.small {
    width: 200px;
  }

  &.x-small {
    width: 100px;
  }
}

.mat-mdc-dialog-container {
  width: 90vw !important;
  height: 90vh !important;
  padding: 0 !important;
}

.mdc-button.mat-mdc-menu-trigger {
  padding: 0 16px;
}

.mat-mdc-form-field:not(.search-bar) {
  padding: 0 5px;
}

.mat-mdc-tab-body-content {
  padding: 20px 0;
}

.search-bar {
  margin-top: 10px;
}

.snack-bar-error {
  --mdc-snackbar-container-color: rgba(207, 69, 69, 0.85) !important;
  --mdc-snackbar-supporting-text-line-height: 30px;
  --mdc-snackbar-supporting-text-size: 16px;
}
