@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();

$ctgb-primary-palette: (
  50: #eae5e3,
  100: #cbbfba,
  200: #a9948c,
  300: #87695e,
  400: #6d483c,
  500: #532819,
  600: #4c2416,
  700: #421e12,
  800: #39180e,
  900: #290f08,
  A100: #ff7e65,
  A200: #ff5332,
  A400: #fe2900,
  A700: #e52500,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$ctgb-accent-palette: (
  50: #f7f7e7,
  100: #ebecc2,
  200: #dde09a,
  300: #cfd372,
  400: #c5c953,
  500: #bbc035,
  600: #b5ba30,
  700: #acb228,
  800: #a4aa22,
  900: #969c16,
  A100: #fdffd0,
  A200: #faff9d,
  A400: #f7ff6a,
  A700: #f6ff51,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);


$ctgb-primary: mat.m2-define-palette($ctgb-primary-palette, 700, 700, 700);
$ctgb-accent: mat.m2-define-palette($ctgb-accent-palette, 200, 100, 300);

$ctgb-theme: mat.m2-define-light-theme((
 color: (
   primary: $ctgb-primary,
   accent: $ctgb-accent,
 )
));

@include mat.all-component-themes($ctgb-theme);

$ctgb-primary-color: mat.m2-get-color-from-palette($ctgb-primary);
$ctgb-accent-color: mat.m2-get-color-from-palette($ctgb-accent, 200);
$text-secondary: rgba(0, 0, 0, 0.54);
$border: rgba(0, 0, 0, 0.12);



html {
  --mdc-typography-button-letter-spacing: normal;
  --mat-table-header-headline-size: 12px;
}
